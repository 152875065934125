import Text from "components/Text";
import { endpoints } from "core/utils/endpoints";
import http from "core/utils/http";
import React, { useEffect, useRef, useState } from "react";
import uploadImg from "../../../assets/file.svg";
import FileList from "./FileList";

const DropFileInput = ({
  fileList,
  setFileList,
  quoteId,
  setQuoteId,
  documentTypes,
  setFileData,
  finalPayloadData,
  setFinalPayLoadData,
  orderDetails,
  handleDocumentDelete,
  handleTypeUpdate,
  selectedDocumentTypes,
  setSelectedDocumentTypes,
}) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    let data = [];
    orderDetails?.formal_quote_items?.map((item) => {
      data.push({ label: item.label, value: item.type });
    });

    setSelectedDocumentTypes(data);
  }, [orderDetails]);

  const addItem = (item) => {
    setSelectedDocumentTypes([...selectedDocumentTypes, item]);

    var tempObject = {};

    fileList?.map((data) => {
      tempObject.formal_document_id = data.id;
      tempObject.type = item;
    });

    setFinalPayLoadData([...finalPayloadData, tempObject]);
  };

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const apiUrl = endpoints.formal.uploadDocument;
    const newFile = e.target.files[0];
    if (newFile) {
      const formData = new FormData();
      if (newFile) {
        if (!quoteId) {
          formData.append("document", newFile);
          http()
            .post(apiUrl, formData)
            .then((res) => {
              if (res.status) {
                const FileResponse = res?.data?.formal_quote_documents;
                setFileList([...FileResponse]);
                setQuoteId(res.data.formal_quote.id);
              }
            });
        } else {
          formData.append("document", newFile);
          formData.append("formal_quote_id", quoteId);
          http()
            .post(apiUrl, formData)
            .then((res) => {
              setFileData(res);
              if (res.status) {
                const FileResponse = res?.data?.formal_quote_documents;
                setFileList([...FileResponse]);
              }
            });
        }
      }
    }
  };

  return (
    <div>
      <div>
        <Text as="h3">Upload certificate/document</Text>
        <div
          ref={wrapperRef}
          className="relative h-[125px] flex items-center justify-center text-black border-2 border-dashed border-[#E1E1E3] mt-6"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="text-center font-semibold p-2">
            <div className="flex items-center gap-4">
              <img src={uploadImg} alt="" className="cursor-pointer" />
              <p className="text-[#5E6B7B] text-[15px] leading-[22px]  font-opensans">
                Drag & drop your files here
              </p>
            </div>
            <button className="bg-[#641CE1] text-white px-8 py-3 rounded-full mt-2 text-[16px] leading-[22px] font-opensans">
              Click & add
            </button>
          </div>
          <input
            multiple
            type="file"
            value=""
            onChange={onFileDrop}
            className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
          />
        </div>
        {fileList?.length > 0 ? (
          <div className="mt-8 text-black">
            {fileList?.map((item, index) => (
              <FileList
                key={item.id}
                item={item}
                handleDeleteFile={handleDocumentDelete}
                selectedDocumentTypes={selectedDocumentTypes}
                addItem={addItem}
                documentTypes={documentTypes}
                index={index}
                handleTypeUpdate={handleTypeUpdate}
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className="mt-8">
        <Text as="h5">How many pages do you need translated?</Text>
        <div className="flex items-start gap-8">
          <div className="flex flex-col">
            <label className="text-[#333941]">Page Count *</label>
            <input
              disabled
              className="outline-none border-b-[1px] border-[#333941]"
              value={fileList?.reduce(
                (prev, next) => prev + +next.total_page_count,
                0
              )}
            />
          </div>
          <p className="text-[#333941] text-[13px] leading-[23px]">
            A page is 250 words or fewer including numbers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DropFileInput;
