// import Layout from "../components/Layout";
import Layout from "components/Layout";
import Quote from "LegalTranscription/Quote";
import React from "react";
// import Quote from "../LegalTranscription/Quote";

function Index() {
  return (
    <Layout>
      <Quote />
    </Layout>
  );
}

export default Index;
