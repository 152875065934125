import React, { useEffect, useState } from "react";
import OrderSummary from "./OrderSummary";
import masterCard from "assets/master-card.svg";
import paypal from "assets/paypal.svg";
import Stripe from "assets/stripe.png";
import { useForm } from "react-hook-form";
import InputText from "components/InputText";
import http from "core/utils/http";
import { endpoints } from "core/utils/endpoints";
import { navigate } from "gatsby";
import PaypalExpressBtn from "gatsby-paypal-button";
import { usePostPaypalDetails } from "hooks/Payment/usePostPaypalDetails";

function Step3({
  data,
  step,
  setStep,
  orderDetails,
  setOrderDetails,
  quoteId,
}) {
  const [paymentDetails, setPaymentDetails] = useState();
  const [paymentError, setPaymentError] = useState();
  const [loading, setLoading] = useState(false);
  const [openFailError, setOpenFailError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("card");

  const { mutateAsync: mutateAsync2 } = usePostPaypalDetails();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    if (quoteId) {
      const apiUrl = `${endpoints.formal.orderSummary}/${quoteId}`;
      http()
        .get(apiUrl)
        .then((res) => {
          setOrderDetails(res);
        });
    }
  }, []);
  const onCancel = (data) => {
    // The user pressed "cancel" or closed the PayPal popup

    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    const postData2 = {
      type: "cancel",
      paypal_info: data,
      quote_id: quoteId,
    };

    mutateAsync2(postData2).then((res) => {});
    setOpenFailError(true);
  };

  const onError = (err) => {
    // The main Paypal script could not be loaded or something blocked the script from loading

    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    const postData2 = {
      type: "error",
      paypal_info: err,
      quote_id: quoteId,
    };

    mutateAsync2(postData2).then((res) => {});
    setOpenFailError(true);
  };

  const loadBlueSnapScript = () => {
    const existingScript = document.getElementById("bluesnap-script");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://gateway.bluesnap.com/js/cse/v1.0.4/bluesnap.js";
      script.id = "bluesnap-script";
      script.async = true;
      script.onload = () => {
        // console.log("BlueSnap script loaded");
        // console.log(window.BlueSnap);
      };
      document.body.append(script);
    }
  };

  useEffect(() => {
    loadBlueSnapScript();
  }, []);

  let env = process.env.GATSBY_PAYPAL_ENV;
  let currency = "USD";
  const client = {
    sandbox: process.env.GATSBY_PAYPAL_SANDBOX_KEY,
    production: process.env.GATSBY_PAYPAL_PRODUCTION_KEY,
  };

  const onSubmit = (data) => {
    var pub_key = process.env.GATSBY_BLUESNAP_PUBLIC_KEY;
    var bluesnap = new window.BlueSnap(pub_key);
    bluesnap.encrypt("cc-checkout-form");
    var form = document.getElementById("cc-checkout-form");
    var formData = new window.FormData(form);
    var encryptedCreditCard = document.getElementById("cc-checkout-form");
    var form = document.getElementById("cc-checkout-form");

    var formInputs =
      document.forms["cc-checkout-form"].getElementsByTagName("input");
    var encryptedCreditCard = formInputs["encryptedCreditCard"]?.value;
    var encryptedCvv = formInputs["encryptedCvv"]?.value;
    const expiryDate = data?.cardExpiry?.split("/");
    const expiryMonth = expiryDate[0];
    const expiryYear = expiryDate[1];

    const payload = {
      card_number: encryptedCreditCard,
      security_code: encryptedCvv,
      expiration_month: expiryMonth,
      expiration_year: expiryYear,
      instructions_translator: data.instructions,
      formal_quote_id: quoteId,
    };
    setLoading(true);
    const apiUrl = endpoints.formal.payment;
    http()
      .post(apiUrl, payload)
      .then((res) => {
        if (res.success) {
          setPaymentDetails(res.data);
          setLoading(false);
          navigate("/quote/success/");
          localStorage.removeItem("step");
        } else {
          setPaymentError(res.message);
          setLoading(false);
        }
      });
  };

  const stripePayload = {
    quote_id: quoteId,
  };

  const redirectToStripeCheckout = (url) => {
    window.location.href = url;
  };

  const stripePayment = () => {
    const apiUrl = endpoints.payment.payment_by_stripe;
    http()
      .post(apiUrl, stripePayload)
      .then((res) => {
        if (res.success) {
          setLoading(false);
          redirectToStripeCheckout(res.data);
          localStorage.removeItem("step");
        } else {
          setLoading(false);
        }
      });
  };

  const onSuccess = (payment) => {
    // 1, 2, and ... Poof! You made it, everything's fine and dandy!
    // console.log("Payment successful!", payment);
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    const postData2 = {
      type: "success",
      paypal_info: payment,
      quote_id: quoteId,
    };

    mutateAsync2(postData2).then((res) => {
      // setPaymentInfo(res);
      // settoggleOrder(true);
      navigate("/quote/success");
      localStorage.removeItem("step");
      // localStorage.removeItem("quoteId");
      //  localStorage.removeItem("order_number");
    });
  };

  return (
    <form
      id="cc-checkout-form"
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-6xl mx-auto flex gap-14 my-24"
    >
      <div className="w-3/5 mt-24">
        <h1
          className="text-lg text-black xs:font-opensans font-bold py-6 cursor-pointer"
          onClick={() => setStep(step - 1)}
        >
          &#x21A9; Go Back
        </h1>
        {paymentError === "Error in payment" && (
          <p className="text-red">{paymentError}</p>
        )}
        <h2 className="text-lg text-black xs:font-opensans font-bold py-6">
          Payment Method
        </h2>
        <div className="flex gap-6">
          <div
            className={`${
              paymentMethod === "card" && "border border-[#460DA9]"
            }  rounded-lg w-40 h-20 flex items-center justify-center cursor-pointer`}
            onClick={() => setPaymentMethod("card")}
          >
            <img src={masterCard} alt="master-card" />
          </div>
          <div
            className={`${
              paymentMethod === "paypal" && "border border-[#460DA9]"
            } rounded-lg w-40 h-20 flex items-center justify-center cursor-pointer bg-[#F5F5F5]`}
            onClick={() => setPaymentMethod("paypal")}
          >
            <img src={paypal} alt="paypal" />
          </div>
          <div
            className={`${
              paymentMethod === "stripe" && "border border-[#460DA9]"
            } rounded-lg w-40 h-20 flex items-center justify-center cursor-pointer bg-[#F5F5F5]`}
            onClick={() => setPaymentMethod("stripe")}
          >
            <img src={Stripe} alt="stripe" />
          </div>
          {/* <div className="rounded-lg w-40 h-20 flex items-center justify-center cursor-pointer text-[#333941] text-4xl bg-[#F5F5F5]">
            +
          </div> */}
        </div>

        {paymentMethod === "card" ? (
          <div className="pt-14 pb-11 space-y-7">
            <InputText
              register={register}
              required
              regOptions={{ required: "true" }}
              name="cardholderName"
              title="Cardholder Name"
              type="text"
              placeholder="John Doe"
            />

            <InputText
              register={register}
              required
              regOptions={{ required: "This is required." }}
              name="cardNumber"
              data-bluesnap="encryptedCreditCard"
              title="Card Number"
              type="number"
              placeholder="000-3433-3433"
            />
            <div className="flex gap-6">
              <div>
                <InputText
                  register={register}
                  required
                  regOptions={{
                    required: "This is required.",
                  }}
                  name="cardExpiry"
                  title="Valid Thru"
                  type="text"
                  placeholder="MM/YYYY"
                  // onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
                />
                {paymentError?.expiration_year && (
                  <p className="text-red">
                    {paymentError.expiration_year.toString()}
                  </p>
                )}
                {paymentError?.expiration_month && (
                  <p className="text-red">
                    {paymentError.expiration_month.toString()}
                  </p>
                )}
              </div>
              <InputText
                register={register}
                required
                regOptions={{ required: "This is required." }}
                name="cvvNumber"
                title="CVV Number"
                type="number"
                data-bluesnap="encryptedCvv"
                placeholder="1234"
                onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
              />
            </div>
            <h1 className="font-opensans text-[18px] leading-[20px] text-black font-semibold">
              Instructions for Translator
            </h1>
            <textarea
              required
              className="text-[20px] form-input focus:ring-0 border border-[#E1E1E3] rounded-[4px] w-full bg-[#F7F8FA] text-[#333941] p-4"
              {...register("instructions", { required: true })}
              rows="4"
              cols="50"
              placeholder="Provide your translator with additional instructions relevant to your order"
            ></textarea>
          </div>
        ) : paymentMethod === "paypal" ? (
          <div className="pt-14">
            <PaypalExpressBtn
              env={env}
              client={client}
              currency={currency}
              total={orderDetails?.formal_quote?.total_price}
              onError={onError}
              onSuccess={onSuccess}
              onCancel={onCancel}
            />
          </div>
        ) : (
          <div className="pt-14 text-black">
            <button
              className="bg-irisPurple text-white text-md px-12 py-3 rounded-full"
              onClick={stripePayment}
            >
              Stripe Checkout
            </button>
          </div>
        )}
      </div>
      <div className="w-2/5">
        <OrderSummary
          data={data}
          step={step}
          setStep={setStep}
          orderDetails={orderDetails}
          loading={loading}
          paymentMethod={paymentMethod}
        />
      </div>
    </form>
  );
}
export default Step3;
