import React, { useEffect, useState } from "react";
import { PopUpFormData, servicesData, StepOneData } from "./sample-data";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Check from "../../assets/check_circle.svg";

function Index() {
  const [orderDetails, setOrderDetails] = useState([]);
  const steps = ["Contacts", "Documents", "Payment"];
  const [quoteId, setQuoteId] = useState("");
  const [status, setStatus] = useState("");

  const location = useLocation();
  const pathname = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const statusUrl = searchParams.get("status");
  const stepUrl = +searchParams.get("step") || 0;
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(stepUrl);
  }, [stepUrl]);

  useEffect(() => {
    setStatus(statusUrl);
  }, [statusUrl]);

  const navigateToStep = (newStep) => {
    searchParams.set("step", newStep);
    navigate(`${pathname}?${searchParams.toString()}`);
  };

  useEffect(() => {
    setQuoteId(JSON.parse(window.localStorage.getItem("quoteId") || null));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("step", step);
    window.localStorage.setItem("quoteId", quoteId);
  }, [step, quoteId]);

  const removeFailedStrip = () => {
    searchParams.delete("status");
    navigate(`${pathname}?${searchParams.toString()}`);
  };

  const renderStep = (stepNo, iterator) => {
    if (iterator < step) {
      return (
        <img
          src={Check}
          className=" rounded-full flex justify-center items-center w-6 h-6 text-white cursor-pointer bg-[#FCCD6A]"
        />
      );
    } else if (iterator === step) {
      return (
        <p className="rounded-full flex justify-center items-center w-6 h-6 text-white cursor-pointer bg-[#641CE1]">
          {iterator + 1}
        </p>
      );
    } else {
      return (
        <p className="rounded-full flex justify-center items-center w-6 h-6 text-white cursor-pointer bg-[#A9B7CB]">
          {iterator + 1}
        </p>
      );
    }
  };

  return (
    <div className="mt-40">
      {/* Steps */}
      <div className="relative max-w-6xl mx-auto">
        <div
          className={`items-baseline mt-4 mb-12 bg-red rounded ${
            status === "cancel" ? "flex" : "hidden"
          }`}
        >
          <p className="px-4 py-2 text-center flex-1 -mr-8">Payment Failed!</p>
          <button className="px-4 py-2" onClick={removeFailedStrip}>
            X
          </button>
        </div>
        <div className="absolute w-1/3 ">
          <div className="ml-4 border border-dashed absolute w-[90%] mt-3 " />
          <div className="w-[90%] ml-4 flex justify-between items-center">
            {steps.map((item, i) => (
              <div
                key={i}
                className="z-20 flex flex-col items-center justify-center"
              >
                <div>{renderStep(step, i)}</div>
              </div>
            ))}
          </div>
          <div className="flex justify-between items-center">
            {steps.map((item) => (
              <p className="text-black" key={item}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
      {step === 0 && (
        <Step1
          data={StepOneData}
          popUpData={PopUpFormData}
          step={step}
          setStep={navigateToStep}
        />
      )}
      {step === 1 && (
        <Step2
          data={servicesData}
          step={step}
          setStep={navigateToStep}
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          quoteId={quoteId}
          setQuoteId={setQuoteId}
        />
      )}
      {step === 2 && (
        <Step3
          data={servicesData}
          step={step}
          setStep={navigateToStep}
          orderDetails={orderDetails}
          quoteId={quoteId}
          setOrderDetails={setOrderDetails}
        />
      )}
    </div>
  );
}

export default Index;
