export default {
  localStorageQuoteIDKey: "quote_id",
  localStorageSelectedQuoteStepKey: "step",
  localStorageAccountInfoKey: "acc_info",
  localStorageAccessTokenKey: "access_token",
  localStorageOrderIDKey: "order_id",
  localStorageEmailKey: "email",
  localStorageProjectNameKey: "project_name",
  localStorageSpecialInstructionsKey: "special_instructions",
};
