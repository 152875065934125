import Text from "../../components/Text";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import textResolver from "../../core/utils/text-resolver";
import Button from "../../components/Button";
import http from "core/utils/http";
import constants from "core/constants";
import { endpoints } from "core/utils/endpoints";
import SignIn from "./SignIn";
import Loader from "components/Loader";

function SignUp({ data, setShowModal, showModal, setStep, show }) {
  const { dataType = 0 } = data;
  const [value, setValue] = useState(false);
  const [showSignIn, setShowSignIn] = useState(show);
  const [checkSignUp, setCheckSignUp] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleChange = (e) => {
    const { checked } = e.target;
    setValue(checked);
  };
  const apiUrl = endpoints.user.signup;
  const onSubmit = (data) => {
    setLoading(true);
    http()
      .post(apiUrl, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        let response = res;
        if (response.success) {
          setLoading(false);
          localStorage.setItem(
            constants.localStorageAccessTokenKey,
            response.access_token
          );
          localStorage.setItem(
            constants.localStorageAccountInfoKey,
            JSON.stringify({ user: response.user })
          );
          setShowSignIn(true);
        } else {
          setLoading(false);
          setCheckSignUp(response.message);
        }
      });
  };

  return (
    <>
      {showSignIn ? (
        <SignIn
          data={data}
          setShowSignIn={setShowSignIn}
          setShowModal={setShowModal}
          showModal={showModal}
          setStep={setStep}
        />
      ) : (
        <div className="bg-white text-black w-[482px] mx-auto py-12 px-12">
          <Text
            as="h1"
            className="text-[35px] leading-[49px] font-opensans font-bold text-center"
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>

          <Text
            as="p"
            className="text-base font-normal leading-[22px]  font-opensans text-center my-8"
          >
            {dataType === 1
              ? data.description
              : textResolver(data, "description")}
          </Text>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-6"
          >
            <div>
              <input
                type="text"
                {...register("username", { required: true })}
                placeholder={
                  dataType === 1
                    ? data.username
                    : textResolver(data, "username")
                }
                className="w-full px-4 py-3 border border-[#CCCCCC] outline-none text-[18px] leading-[24px] rounded-lg font-normal font-opensans text-[#A1A1AA]"
              />
              {errors.username && (
                <Text as="p" className="text-red">
                  {dataType === 1
                    ? data.usernameError
                    : textResolver(data, "usernameError")}
                </Text>
              )}
              {checkSignUp === "Username Exists" ? (
                <p className="text-xs -mt-1 font-opensans font-normal text-left w-full text-red">
                  This UserName is already exists
                </p>
              ) : null}
            </div>
            <div>
              <input
                type="email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid e-mail address",
                  },
                })}
                placeholder={
                  dataType === 1 ? data.email : textResolver(data, "email")
                }
                className="w-full px-4 py-3 border border-[#CCCCCC] outline-none text-[18px] leading-[24px] rounded-lg font-normal font-opensans text-[#A1A1AA]"
              />
              {errors.email && (
                <Text as="p" className="text-red">
                  {dataType === 1
                    ? data.emailError
                    : textResolver(data, "emailError")}
                </Text>
              )}
              {checkSignUp === "Email Exists" ? (
                <span className="text-xs font-opensans font-normal text-left w-full text-red">
                  This email is already exists
                </span>
              ) : null}
            </div>

            <div>
              <input
                type="password"
                {...register("password", { required: true })}
                placeholder={
                  dataType === 1
                    ? data.password
                    : textResolver(data, "password")
                }
                className="w-full px-4 py-3 border border-[#CCCCCC] outline-none text-[18px] leading-[24px] rounded-lg font-normal font-opensans text-[#A1A1AA]"
              />
              {errors.password && (
                <Text as="p" className="text-red">
                  {dataType === 1
                    ? data.passwordError
                    : textResolver(data, "passwordError")}
                </Text>
              )}

              {checkSignUp.password && (
                <p className="text-red">{checkSignUp.password.toString()}</p>
              )}
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                className="mt-1 block  border-light-silver text-[14px] border focus:ring-0 font-opensans"
                onChange={(e) => handleChange(e)}
                defaultChecked={value}
              />
              <Text
                as="p"
                className="text-base leading-[24px] font-opensans font-normal"
              >
                {dataType === 1
                  ? data.checkbox
                  : textResolver(data, "checkbox")}
              </Text>
            </div>

            <Button
              type="submit"
              title={
                loading ? (
                  <Loader />
                ) : dataType === 1 ? (
                  data.cta.text
                ) : (
                  textResolver(data.cta, "text")
                )
              }
              className="bg-irisPurple rounded-full h-[56px] flex items-center justify-center text-[20px] leading-[27px] text-white"
            />

            <div className="text-center">
              <Text as="span" className="text-center text-base leading-[21px]">
                {dataType === 1
                  ? data.signUpDescription1
                  : textResolver(data, "signUpDescription1")}
              </Text>{" "}
              <Text
                as="span"
                className="text-center text-base leading-[21px]"
                onClick={() => setShowSignIn(true)}
              >
                {dataType === 1
                  ? data.signUpDescription2
                  : textResolver(data, "signUpDescription2")}
              </Text>{" "}
              <Text as="span" className="text-center text-base leading-[21px]">
                {dataType === 1
                  ? data.signUpDescription3
                  : textResolver(data, "signUpDescription3")}
              </Text>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default SignUp;
