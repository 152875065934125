import { useMutation, useQueryClient } from "react-query";
import { endpoints } from "core/utils/endpoints";
import http from "core/utils/http";

const PostPaypalPayment = (postData) => {
  return http().post(endpoints.payment.payment_by_paypal, postData);
};

export function usePostPaypalDetails() {
  const queryClient = useQueryClient();
  return useMutation(PostPaypalPayment, {
    onSuccess: () => {
      queryClient.invalidateQueries(["PostPayment"]);
    },
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}
