import Text from "components/Text";
import textResolver from "core/utils/text-resolver";
import React, { Fragment, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderImg1 from "../../assets/slider-img1.png";
import Next from "../../assets/Next.svg";
import Prev from "../../assets/Prev.svg";
import Star from "../../assets/star.svg";
import Ata from "../../images/ata.png";
import ISO from "../../images/iso.png";
import { sliderData } from "./sample-data";
import InputText from "components/InputText";
import Button from "components/Button";
import SignUp from "./SignUp";
import Modal from "components/Modal";
import { endpoints } from "core/utils/endpoints";
import http from "core/utils/http";
import Loader from "components/Loader";
import constants from "core/constants";
import ata from "assets/ata.svg";
import iso9001 from "assets/iso-9001.svg";

function Step1({ data, popUpData, step, setStep }) {
  const { dataType = 0 } = data;
  const sliderRef = useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    const apiUrl = endpoints.formal.guestUser;
    setLoading(true);
    http()
      .post(apiUrl, data)
      .then((res) => {
        let response = res;
        if (response.success) {
          setLoading(false);
          localStorage.setItem(
            constants.localStorageAccessTokenKey,
            response.access_token
          );
          localStorage.setItem(
            constants.localStorageAccountInfoKey,
            JSON.stringify({ user: response.data.user })
          );
          setStep(step + 1);
        } else {
          setLoading(false);
          setError(response.message);
        }
      });
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="relative text-black max-w-6xl mx-auto flex justify-between items-center my-24 w-full h-[850px] gap-8">
      <Modal
        visible={showModal}
        onClick={() => setShowModal(!showModal)}
        className="bg-white w-auto h-auto py-6 px-6 "
      >
        <SignUp
          data={popUpData}
          setShowModal={setShowModal}
          showModal={showModal}
          setStep={setStep}
          show={show}
        />
      </Modal>
      <div>
        <Text
          as="h1"
          className="text-[28px] leading-[45px] font-bold font-opensans mb-4"
        >
          Welcome to Formal Translations - <br />{" "}
          <span
            className="text-[#641CE1] underline cursor-pointer"
            onClick={() => {
              setShowModal(!showModal);
              setShow(true);
            }}
          >
            {" "}
            Sign in
          </span>{" "}
          or continue as a guest.
        </Text>
        <Text as="span" className="text-[#333941] text-base leading-[22px]">
          {dataType === 1 ? data.span1 : textResolver(data, "span1")}
        </Text>{" "}
        <Text
          as="span"
          className="text-[#333941] text-base leading-[22px] cursor-pointer border-b-[1px] border-irisPurple"
          onClick={() => {
            setShowModal(!showModal);
            setShow(false);
          }}
        >
          {dataType === 1 ? data.span2 : textResolver(data, "span2")}
        </Text>{" "}
        <Text
          as="span"
          className="text-[#333941] text-base leading-[22px] block mt-1"
        >
          {dataType === 1 ? data.span3 : textResolver(data, "span3")}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)} className="my-8 space-y-6">
          <div>
            <InputText
              register={register}
              regOptions={{ required: true }}
              name="full_name"
              title="Full Name*"
              type="text"
            />
            {error === "Username Exists" && (
              <p className="text-red text-xs">Username Exists</p>
            )}
            <p
              className={`text-xs text-red -mt-2 ${
                errors.name ? "visible" : "invisible"
              }`}
            >
              This is required.
            </p>
          </div>
          <div>
            <InputText
              register={register}
              regOptions={{
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              }}
              name="email"
              title="Email*"
              type="email"
            />
            {error === "Email Exists" && (
              <p className="text-red text-xs">This email is already exists</p>
            )}
            <p
              className={`text-xs text-red -mt-2 ${
                errors.email ? "visible" : "invisible"
              }`}
            >
              Please enter a valid email. for example("abc@gmail.com")
            </p>
          </div>
          <Button
            type="submit"
            title={
              loading ? (
                <Loader />
              ) : dataType === 1 ? (
                data.cta.text
              ) : (
                textResolver(data.cta, "text")
              )
            }
            className="bg-irisPurple rounded-full w-60 h-12 text-white"
          />
        </form>
        <div className="flex gap-8">
          <img src={ata} alt="" />
          <img src={iso9001} alt="" />
        </div>
      </div>
      <div className="absolute top-0 right-0 w-[650px]  overflow-x-hidden">
        <Slider ref={sliderRef} {...settings}>
          {sliderData.map((slider, i) => (
            <div key={slider.image}>
              <div className="px-4">
                <img src={slider.image} />
              </div>
              <div className="absolute bottom-0 z-10 px-[60px] py-8">
                <div className="flex gap-4">
                  <img src={Star} />
                  <img src={Star} />
                  <img src={Star} />
                  <img src={Star} />
                  <img src={Star} />
                </div>
                <Text
                  as="p"
                  className="text-white my-4 font-opensans  w-[542px] text-[24px] leading-[33px] font-bold"
                >
                  {dataType === 1
                    ? slider.review
                    : textResolver(slider, "review")}
                </Text>

                <div className="flex justify-between items-center">
                  <div className="text-white">
                    <Text
                      as="p"
                      className="font-bold text-[24px] leading-[33px] font-opensans h-[33px]"
                    >
                      {dataType === 1
                        ? slider.name
                        : textResolver(slider, "name")}
                    </Text>

                    <Text
                      as="p"
                      className="font-bold text-[18px] leading-[25px] font-opensans"
                    >
                      {dataType === 1
                        ? slider.country
                        : textResolver(slider, "country")}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="z-20 absolute bottom-0 right-[85px] pb-8 flex justify-center space-x-4">
          <img
            src={Prev}
            alt=""
            onClick={() => sliderRef.current.slickPrev()}
            className="cursor-pointer"
          />
          <img
            src={Next}
            alt=""
            onClick={() => sliderRef.current.slickNext()}
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}

export default Step1;
