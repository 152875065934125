import Text from "../../components/Text";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import textResolver from "../../core/utils/text-resolver";
import Button from "../../components/Button";
import http from "core/utils/http";
import constants from "core/constants";
import { endpoints } from "core/utils/endpoints";
import Loader from "components/Loader";

function SignIn({ data, setShowSignIn, setShowModal, showModal, setStep }) {
  const { dataType = 0 } = data;
  const [value, setValue] = useState(false);
  const [checkSignIn, setCheckSignIn] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleChange = (e) => {
    const { checked } = e.target;
    setValue(checked);
  };

  const apiUrl = endpoints.user.login;
  const onSubmit = (data) => {
    setLoading(true);
    http()
      .post(apiUrl, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        let response = res;
        if (response.success) {
          setLoading(false);
          localStorage.setItem(
            constants.localStorageAccessTokenKey,
            response.access_token
          );
          localStorage.setItem(
            constants.localStorageAccountInfoKey,
            JSON.stringify({ user: response.user })
          );
          setShowModal(!showModal);
          setStep(1);
        } else {
          setCheckSignIn(response.message);
          setLoading(false);
        }
      });
  };

  return (
    <div className="bg-white text-black w-[482px] mx-auto py-12 px-12">
      <Text
        as="h1"
        className="text-[35px] leading-[49px] font-opensans font-bold text-center"
      >
        {dataType === 1 ? data.signIn : textResolver(data, "signIn")}
      </Text>

      <Text
        as="p"
        className="text-base font-normal leading-[22px]  font-opensans text-center my-8"
      >
        {dataType === 1
          ? data.signInDescription
          : textResolver(data, "signInDescription")}
      </Text>

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div>
          <input
            type="text"
            {...register("username", { required: true })}
            placeholder={
              dataType === 1 ? data.username : textResolver(data, "username")
            }
            className="w-full px-4 py-3 border border-[#CCCCCC] outline-none text-[18px] leading-[24px] rounded-lg font-normal font-opensans text-[#A1A1AA]"
          />
          {errors.username && (
            <Text as="p" className="text-red">
              {dataType === 1
                ? data.usernameError
                : textResolver(data, "usernameError")}
            </Text>
          )}
        </div>
        <div>
          <input
            type="password"
            {...register("password", { required: true })}
            placeholder={
              dataType === 1 ? data.password : textResolver(data, "password")
            }
            className="w-full px-4 py-3 border border-[#CCCCCC] outline-none text-[18px] leading-[24px] rounded-lg font-normal font-opensans text-[#A1A1AA]"
          />
          {errors.password && (
            <Text as="p" className="text-red">
              {dataType === 1
                ? data.passwordError
                : textResolver(data, "passwordError")}
            </Text>
          )}
        </div>
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            className="mt-1 block  border-light-silver text-[14px] border focus:ring-0 font-opensans"
            onChange={(e) => handleChange(e)}
            defaultChecked={value}
          />
          <Text
            as="p"
            className="text-base leading-[24px] font-opensans font-normal"
          >
            {dataType === 1 ? data.checkbox : textResolver(data, "checkbox")}
          </Text>
        </div>

        {checkSignIn.length > 0 && (
          <p className="text-red text-center">{checkSignIn}</p>
        )}

        <Button
          type="submit"
          title={
            loading ? (
              <Loader />
            ) : dataType === 1 ? (
              data.signIn
            ) : (
              textResolver(data, "signIn")
            )
          }
          className="bg-irisPurple rounded-full h-[56px] flex items-center justify-center text-[20px] leading-[27px] text-white"
        />
        <Text as="span" className="text-center text-base leading-[21px]">
          Don't have an account?{" "}
          <span
            onClick={() => setShowSignIn(false)}
            className="text-irisPurple underline cursor-pointer"
          >
            SignUp
          </span>{" "}
          Now
        </Text>
      </form>
    </div>
  );
}

export default SignIn;
