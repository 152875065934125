import React from "react";
import Cross from "../../../assets/cross.svg";
import Select from "react-select";

function FileList({
  item,
  handleDeleteFile,
  documentTypes,
  selectedDocumentTypes,
  index,
  handleTypeUpdate,
}) {
  const getFileName = (str) =>
    str?.length > 22 ? str?.substr(0, 11) + "..." + str?.substr(-11) : str;

  return (
    <div className="relative flex mb-2 p-4">
      <div className="flex w-full justify-between items-center">
        <div className="flex gap-4">
          <img
            className="cursor-pointer"
            src={Cross}
            onClick={() => handleDeleteFile(item.id)}
          />
          <p>{getFileName(item.name)}</p>
        </div>
        <div className="w-2/5">
          <Select
            value={
              documentTypes?.filter(
                (item) => item.value === selectedDocumentTypes[index]?.value
              )[0]
            }
            onChange={(e) => handleTypeUpdate(item.id, e.value)}
            options={documentTypes}
            placeholder="Select type of certificate/doc"
          />
        </div>
      </div>
    </div>
  );
}

export default FileList;
