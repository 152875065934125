import { endpoints } from "core/utils/endpoints";
import http from "core/utils/http";
import { useQuery } from "react-query";

export const UseDeleteDocument = (id) => {
  const newEndpoints = endpoints.formal.deleteDocument.replace(
    "/api/v1/formal/delete-document?id={9}",
    `/api/v1/formal/delete-document?id=${id}`
  );
  return http().get(newEndpoints);
};
