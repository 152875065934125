import React from "react";

const InputText = ({
  title,
  name,
  type = "text",
  regOptions = {},
  register,
  ...props
}) => {
  return (
    <div className="grid grid-cols-1 gap-2 text-[14px] text-black my-2 md:my-2 w-full">
      {title && (
        <div className="font-opensans text-[18px] leading-[20px] text-black font-semibold">
          {title}
        </div>
      )}
      <div className="w-full">
        <div className="flex text-[20px] text-sonic-silver justify-between items-center cursor-pointer w-full">
          <input
            className="form-input focus:ring-0 border border-[#E1E1E3] rounded-[4px] h-[50px] w-full bg-[#F7F8FA] text-[#333941]"
            type={type}
            {...register(name, regOptions)}
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

export default InputText;
