export const languages = [
  { label: "Arabic", value: "Arabic" },
  { label: "Bulgarian", value: "Bulgarian" },
  { label: "Burmese", value: "Burmese" },
  { label: "Chinese", value: "Chinese" },
  { label: "Czech", value: "Czech" },
  { label: "Danish", value: "Danish" },
  { label: "Dutch", value: "Dutch" },
  { label: "English", value: "English" },
  { label: "Finnish", value: "Finnish" },
  { label: "French", value: "French" },
  { label: "German", value: "German" },
  { label: "Greek", value: "Greek" },
  { label: "Haitian Creole", value: "Haitian Creole" },
  { label: "Hebrew", value: "Hebrew" },
  { label: "Hindi", value: "Hindi" },
  { label: "Hungarian", value: "Hungarian" },
  { label: "Indonesian", value: "Indonesian" },
  { label: "Italian", value: "Italian" },
  { label: "Japanese", value: "Japanese" },
  { label: "Korean", value: "Korean" },
  { label: "Lao", value: "Lao" },
  { label: "Latin", value: "Latin" },
  { label: "Marathi", value: "Marathi" },
  { label: "Marshallese", value: "Marshallese" },
  { label: "Navajo", value: "Navajo" },
  { label: "Norwegian", value: "Norwegian" },
  { label: "Polish", value: "Polish" },
  { label: "Portuguese", value: "Portuguese" },
  { label: "Romanian", value: "Romanian" },
  { label: "Russian", value: "Russian" },
  { label: "Spanish", value: "Spanish" },
  { label: "Swedish", value: "Swedish" },
  { label: "Thai", value: "Thai" },
  { label: "Tigrinya", value: "Tigrinya" },
  { label: "Turkish", value: "Turkish" },
  { label: "Vietnamese", value: "Vietnamese" },
  { label: "Welsh", value: "Welish" },
  { label: "Yiddish", value: "Yiddish" },
];
