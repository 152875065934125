import Text from "components/Text";
import React, { useEffect, useState } from "react";
import DropFileInput from "./DropFileInput";
import OrderSummary from "./OrderSummary";
import Select from "react-select";
import http from "core/utils/http";
import { endpoints } from "core/utils/endpoints";
import constants from "core/constants";
import { languages } from "LegalTranscription/utils/languagesData";
import { UseDeleteDocument } from "hooks/useDeleteDocument";

function Step2({
  data,
  step,
  setStep,
  setOrderDetails,
  orderDetails,
  quoteId,
  setQuoteId,
}) {
  const user = localStorage.getItem(constants.localStorageAccountInfoKey);
  const userData = JSON.parse(user);

  const userId = userData?.user?.id;
  const [fileList, setFileList] = useState([]);
  const [fileData, setFileData] = useState({});
  const [translateFrom, setTranslateFrom] = useState("");
  const [translateTo, setTranslateTo] = useState("");
  const [translateFromSelected, setTranslateFromSelected] = useState("");
  const [translateToSelected, setTranslateToSelected] = useState("");
  const [isToggled, setToggle] = useState(true);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finalPayloadData, setFinalPayLoadData] = useState([]);
  const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);

  //Get Document Types
  useEffect(() => {
    const apiUrl = endpoints.formal.documentTypes;
    http()
      .get(apiUrl)
      .then((res) => {
        setDocumentTypes(res);
      });
  }, []);

  //Get Prefilled Data
  useEffect(() => {
    if (quoteId) {
      const apiUrl = `${endpoints.formal.orderSummary}/${quoteId}`;
      http()
        .get(apiUrl)
        .then((res) => {
          setOrderDetails(res);

          let data = res?.formal_quote_items?.map(
            ({ id: formal_document_id, type, ...rest }) => {
              return { formal_document_id, type };
            }
          );

          setFileList(res?.formal_quote_documents);
          setFinalPayLoadData(data);
          setTranslateFromSelected(res?.formal_quote?.source_language);
          setTranslateToSelected(res?.formal_quote?.target_language);
          setFinalPayLoadData(data);
        });
    }
  }, [quoteId]);

  const handleDocumentDelete = (id) => {
    UseDeleteDocument(id).then((res) => {
      setFileList(res.data?.formal_quote_documents);
      setOrderDetails(res.data);
      setTranslateFromSelected(res?.data?.formal_quote?.source_language);
      setTranslateToSelected(res?.data?.formal_quote?.target_language);
      setFileList(res?.data?.formal_quote_documents);
    });
  };

  const handleTypeUpdate = (id, type) => {
    const apiUrl = endpoints.formal.updateDocument;
    const payload = { document_id: id, type: type };
    http()
      .post(apiUrl, payload)
      .then((res) => {
        setFileList(res.data?.formal_quote_documents);
        setOrderDetails(res.data);
        setTranslateFromSelected(res?.data?.formal_quote?.source_language);
        setTranslateToSelected(res?.data?.formal_quote?.target_language);
        setFileList(res?.data?.formal_quote_documents);
      });
  };

  const handleToggle = () => {
    setToggle(!isToggled);
  };

  let payload = {};

  let updatedPayload = {
    ...payload,
    source_language: translateFromSelected,
    target_language: translateToSelected,
    quote_type: isToggled ? "certified_translation" : "standard_translation",
    user_id: userId,
    formal_quote_id: quoteId,
  };

  useEffect(() => {
    const apiUrl = endpoints.formal.saveQuote;
    if (translateFromSelected && translateToSelected && userId && fileList) {
      setLoading(true);
      http()
        .post(apiUrl, updatedPayload)
        .then((res) => {
          if (res.status) {
            setOrderDetails(res.data);
            setFinalPayLoadData(res.data.formal_quote_items);
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
    }
  }, [userId, translateFromSelected, translateToSelected, fileList]);

  const validateStep2 = () => {
    let isNull = false;
    selectedDocumentTypes.map((selectedDocumentType) => {
      if (!selectedDocumentType.value) {
        isNull = true;
      }
    });

    if (
      fileList.length > 0 &&
      fileList.length === selectedDocumentTypes.length &&
      !isNull &&
      translateFromSelected &&
      translateToSelected
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className="flex items-start text-black max-w-6xl mx-auto gap-12 my-24">
      {loading && (
        <div className="fixed inset-0 bg-white opacity-50 z-10">
          <div className="flex items-center justify-center mt-48">
            <div role="status">
              <svg
                aria-hidden="true"
                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#641CE1"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="black"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="space-y-8 w-[60%] mt-24">
        <DropFileInput
          fileList={fileList}
          setFileList={setFileList}
          quoteId={quoteId}
          setQuoteId={setQuoteId}
          documentTypes={documentTypes}
          setFileData={setFileData}
          finalPayloadData={finalPayloadData}
          setFinalPayLoadData={setFinalPayLoadData}
          orderDetails={orderDetails}
          handleDocumentDelete={handleDocumentDelete}
          handleTypeUpdate={handleTypeUpdate}
          selectedDocumentTypes={selectedDocumentTypes}
          setSelectedDocumentTypes={setSelectedDocumentTypes}
        />

        <div>
          <Text as="h5">Select source language and target language</Text>
          <div className="w-3/4 grid grid-cols-2 gap-6 mt-6">
            <div>
              <Select
                value={
                  languages.filter(
                    (item) =>
                      item.value === (translateFromSelected || translateFrom)
                  )[0]
                }
                onChange={(e) => {
                  setTranslateFromSelected(e.value);
                }}
                options={languages}
                placeholder="Translate From *"
              />
            </div>
            <div>
              <Select
                value={
                  languages.filter(
                    (item) =>
                      item.value === (translateToSelected || translateTo)
                  )[0]
                }
                onChange={(e) => {
                  setTranslateToSelected(e.value);
                }}
                options={languages.filter(
                  (item) =>
                    item.value !== (translateFromSelected || translateFrom)
                )}
                placeholder="Translate To *"
              />
            </div>
          </div>
        </div>
        <div className="p-8 border border-[#ADB5BD] bg-[#d9d9d91a] space-y-6">
          <Text as="h5">What type of translation do you need?</Text>
          <div className="flex justify-between items-center">
            <div>
              <Text as="h5">Certified Translation</Text>
              <p className="text-[#333941]">
                A translation by an accredited professional for official use or
                formal
                <br /> procedures. Comes with a free Certificate of Translation
                Accuracy.
              </p>
            </div>
            <label className="switch">
              <input
                type="checkbox"
                defaultChecked={isToggled}
                onClick={handleToggle}
                readOnly
              />
              <span className="slider"></span>
            </label>
          </div>
          <div>
            <div className="flex justify-between items-center">
              <div>
                <Text as="h5">Standard Translation</Text>
                <p className="text-[#333941]">
                  A translation by a professional linguist for business or
                  personal use.
                </p>
              </div>
              <label className="switch">
                <input type="checkbox" checked={!isToggled} readOnly />
                <span className="slider"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="w-[40%]">
        <OrderSummary
          data={data}
          step={step}
          setStep={setStep}
          orderDetails={orderDetails}
          setFileList={setFileList}
          handleDocumentDelete={handleDocumentDelete}
          validateStep2={validateStep2}
        />
      </div>
    </div>
  );
}

export default Step2;
